import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }

  &:not(:last-of-type) {
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &:nth-of-type(odd) {
    .project-content {
      grid-column: 7 / -1;
      text-align: right;

      @media (max-width: 1080px) {
        grid-column: 5 / -1;
      }
      @media (max-width: 768px) {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
      }
      @media (max-width: 480px) {
        padding: 25px 25px 20px;
      }
    }
    .project-tech-list {
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      li {
        margin: 0 0 5px 20px;

        @media (max-width: 768px) {
          margin: 0 10px 5px 0;
        }
      }
    }
    .project-links {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
    .project-image {
      grid-column: 1 / 8;

      @media (max-width: 768px) {
        grid-column: 1 / -1;
      }
    }
  }

  .project-content {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;

    @media (max-width: 1080px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      z-index: 5;
    }

    @media (max-width: 480px) {
      padding: 30px 25px 20px;
    }
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

    @media (max-width: 768px) {
      color: var(--white);

      a {
        position: static;

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .project-description {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    color: var(--light-slate);
    font-size: var(--fz-lg);

    @media (max-width: 768px) {
      padding: 20px 0;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    strong {
      color: var(--white);
      font-weight: normal;
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 0 20px 5px 0;
      color: var(--light-slate);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin: 10px 0;

      li {
        margin: 0 10px 5px 0;
        color: var(--lightest-slate);
      }
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .cta {
      ${({ theme }) => theme.mixins.smallButton};
      margin: 10px;
    }
  }

  .project-image {
    ${({ theme }) => theme.mixins.boxShadow};
    grid-column: 6 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      grid-column: 1 / -1;
      height: 100%;
      opacity: 0.25;
    }

    a {
      width: 100%;
      height: 100%;
      background-color: var(--green);
      border-radius: var(--border-radius);
      vertical-align: middle;

      &:hover,
      &:focus {
        background: transparent;
        outline: 0;

        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
      }
    }

    .img {
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1) brightness(90%);

      @media (max-width: 768px) {
        object-fit: cover;
        width: auto;
        height: 100%;
        filter: grayscale(100%) contrast(1) brightness(50%);
      }
    }
  }
`;

const Featured = () => {
  // 1) Remove `cta` from the query:
  const data = useStaticQuery(graphql`
    {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/featured/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              tech
              github
              external
              # cta REMOVED
            }
            html
          }
        }
      }
    }
  `);

  // 2) No `cta` in the destructuring:
  const featuredProjects = data.featured.edges.filter(({ node }) => node);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, [prefersReducedMotion]);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Projects
      </h2>

      <StyledProjectsGrid>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, github, cover } = frontmatter; // no cta
            const image = getImage(cover);

            return (
              <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                <div className="project-content">
                  <div>
                    <p className="project-overline">Featured Project</p>

                    <h3 className="project-title">
                      <a href={external}>{title}</a>
                    </h3>

                    <div
                      className="project-description"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />

                    {tech.length && (
                      <ul className="project-tech-list">
                        {tech.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                    )}

                    <div className="project-links">
                      {/* 3) Remove references to cta:
                      {cta && (
                        <a href={cta} aria-label="Course Link" className="cta">
                          Learn More
                        </a>
                      )} */}
                      {github && (
                        <a href={github} aria-label="GitHub Link">
                          <Icon name="GitHub" />
                        </a>
                      )}
                      {external && (
                        <a href={external} aria-label="External Link" className="external">
                          <Icon name="External" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                <div className="project-image">
                  <a href={external ? external : github ? github : '#'}>
                    <GatsbyImage image={image} alt={title} className="img" />
                  </a>
                </div>
              </StyledProject>
            );
          })}
      </StyledProjectsGrid>
    </section>
  );
};

export default Featured;


// Featured.jsx
// import React, { useEffect, useRef, useState } from 'react';
// import styled from 'styled-components';

// // If you don’t actually use ScrollReveal or the Icon, you can remove these.
// import sr from '@utils/sr';
// import { srConfig } from '@config';
// import { Icon } from '@components/icons';
// import { usePrefersReducedMotion } from '@hooks';

// // REPLACE THESE IMPORTS WITH YOUR ACTUAL IMAGE FILES
// import hokieAIImage1 from '../../images/1.png';
// import hokieAIImage2 from '../../images/2.png';
// import hokieAIImage3 from '../../images/3.png';
// import debugMasterImg from '../../images/debugMaster.jpg';
// import nbaPredictorImg from '../../images/nbaPredictor.jpg';

// /* ------------------------------------------
//  *  Slideshow: cycles through images on hover
//  * ------------------------------------------ */
// const SlideshowContainer = styled.div`
//   position: relative;
//   overflow: hidden;

//   /* Constrain the width so images aren't huge */
//   max-width: 600px;

//   /* Center if you want (optional) */
//   margin: 0 auto;

//   img {
//     display: fit;
//     width: 70%;
//     height: 10%;
//     border-radius: var(--border-radius);
//   }
// `;

// const Slideshow = ({ images = [] }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [hovered, setHovered] = useState(false);

//   useEffect(() => {
//     let intervalId;

//     if (hovered && images.length > 1) {
//       // Cycle images every 1 second while hovered
//       intervalId = setInterval(() => {
//         setCurrentIndex(prev => (prev + 1) % images.length);
//       }, 1000);
//     } else {
//       // When not hovering, reset to the first image
//       setCurrentIndex(0);
//     }

//     return () => {
//       if (intervalId) clearInterval(intervalId);
//     };
//   }, [hovered, images]);

//   if (!images.length) {
//     return null;
//   }

//   return (
//     <SlideshowContainer
//       onMouseEnter={() => setHovered(true)}
//       onMouseLeave={() => setHovered(false)}>
//       <img src={images[currentIndex]} alt="Project slideshow" />
//     </SlideshowContainer>
//   );
// };

// /* ------------------------------------------
//  *  Styles for the Featured Projects Section
//  * ------------------------------------------ */
// const StyledProjectsGrid = styled.ul`
//   ${({ theme }) => theme.mixins.resetList};

//   a {
//     position: relative;
//     z-index: 1;
//   }
// `;

// const StyledProject = styled.li`
//   position: relative;
//   display: grid;
//   grid-gap: 10px;
//   grid-template-columns: repeat(12, 1fr);
//   align-items: center;

//   @media (max-width: 768px) {
//     ${({ theme }) => theme.mixins.boxShadow};
//   }

//   &:not(:last-of-type) {
//     margin-bottom: 100px;

//     @media (max-width: 768px) {
//       margin-bottom: 70px;
//     }
//     @media (max-width: 480px) {
//       margin-bottom: 30px;
//     }
//   }

//   &:nth-of-type(odd) {
//     .project-content {
//       grid-column: 7 / -1;
//       text-align: right;

//       @media (max-width: 1080px) {
//         grid-column: 5 / -1;
//       }
//       @media (max-width: 768px) {
//         grid-column: 1 / -1;
//         padding: 40px 40px 30px;
//         text-align: left;
//       }
//       @media (max-width: 480px) {
//         padding: 25px 25px 20px;
//       }
//     }
//     .project-tech-list {
//       justify-content: flex-end;
//       @media (max-width: 768px) {
//         justify-content: flex-start;
//       }
//       li {
//         margin: 0 0 5px 20px;
//         @media (max-width: 768px) {
//           margin: 0 10px 5px 0;
//         }
//       }
//     }
//     .project-links {
//       justify-content: flex-end;
//       margin-left: 0;
//       margin-right: -10px;
//       @media (max-width: 768px) {
//         justify-content: flex-start;
//         margin-left: -10px;
//         margin-right: 0;
//       }
//     }
//     .project-image {
//       grid-column: 1 / 8;
//       @media (max-width: 768px) {
//         grid-column: 1 / -1;
//       }
//     }
//   }

//   .project-content {
//     position: relative;
//     grid-column: 1 / 7;
//     grid-row: 1 / -1;

//     @media (max-width: 1080px) {
//       grid-column: 1 / 9;
//     }
//     @media (max-width: 768px) {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       height: 100%;
//       grid-column: 1 / -1;
//       padding: 40px 40px 30px;
//       z-index: 5;
//     }
//     @media (max-width: 480px) {
//       padding: 30px 25px 20px;
//     }
//   }

//   .project-overline {
//     margin: 10px 0;
//     color: var(--green);
//     font-family: var(--font-mono);
//     font-size: var(--fz-xs);
//     font-weight: 400;
//   }

//   .project-title {
//     color: var(--lightest-slate);
//     font-size: clamp(24px, 5vw, 28px);
//     @media (min-width: 768px) {
//       margin: 0 0 20px;
//     }
//     @media (max-width: 768px) {
//       color: var(--white);
//       a {
//         position: static;
//         &:before {
//           content: '';
//           display: block;
//           position: absolute;
//           z-index: 0;
//           width: 100%;
//           height: 100%;
//           top: 0;
//           left: 0;
//         }
//       }
//     }
//   }

//   .project-description {
//     ${({ theme }) => theme.mixins.boxShadow};
//     position: relative;
//     z-index: 2;
//     padding: 25px;
//     border-radius: var(--border-radius);
//     background-color: var(--light-navy);
//     color: var(--light-slate);
//     font-size: var(--fz-lg);

//     @media (max-width: 768px) {
//       padding: 20px 0;
//       background-color: transparent;
//       box-shadow: none;
//       &:hover {
//         box-shadow: none;
//       }
//     }

//     a {
//       ${({ theme }) => theme.mixins.inlineLink};
//     }
//     strong {
//       color: var(--white);
//       font-weight: normal;
//     }
//   }

//   .project-tech-list {
//     display: flex;
//     flex-wrap: wrap;
//     position: relative;
//     z-index: 2;
//     margin: 25px 0 10px;
//     padding: 0;
//     list-style: none;

//     li {
//       margin: 0 20px 5px 0;
//       color: var(--light-slate);
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);
//       white-space: nowrap;
//     }
//     @media (max-width: 768px) {
//       margin: 10px 0;
//       li {
//         margin: 0 10px 5px 0;
//         color: var(--lightest-slate);
//       }
//     }
//   }

//   .project-links {
//     display: flex;
//     align-items: center;
//     position: relative;
//     margin-top: 10px;
//     margin-left: -10px;
//     color: var(--lightest-slate);

//     a {
//       ${({ theme }) => theme.mixins.flexCenter};
//       padding: 10px;
//       &.external {
//         svg {
//           width: 22px;
//           height: 22px;
//           margin-top: -4px;
//         }
//       }
//       svg {
//         width: 20px;
//         height: 20px;
//       }
//     }

//     .cta {
//       ${({ theme }) => theme.mixins.smallButton};
//       margin: 10px;
//     }
//   }

//   .project-image {
//     ${({ theme }) => theme.mixins.boxShadow};
//     grid-column: 6 / -1;
//     grid-row: 1 / -1;
//     position: relative;
//     z-index: 1;
//     @media (max-width: 768px) {
//       grid-column: 1 / -1;
//       height: 100%;
//       opacity: 0.25;
//     }

//     a {
//       width: 100%;
//       height: 100%;
//       background-color: var(--green);
//       border-radius: var(--border-radius);
//       vertical-align: middle;

//       &:hover,
//       &:focus {
//         background: transparent;
//         outline: 0;
//         &:before,
//         .img {
//           background: transparent;
//           filter: none;
//         }
//       }

//       &:before {
//         content: '';
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         z-index: 3;
//         transition: var(--transition);
//         background-color: var(--navy);
//         mix-blend-mode: screen;
//       }
//     }

//     .img {
//       border-radius: var(--border-radius);
//       mix-blend-mode: multiply;
//       filter: grayscale(100%) contrast(1) brightness(90%);

//       @media (max-width: 768px) {
//         object-fit: cover;
//         width: auto;
//         height: 100%;
//         filter: grayscale(100%) contrast(1) brightness(50%);
//       }
//     }
//   }
// `;

// /* ------------------------------------------
//  *  Hardcoded Featured Projects
//  * ------------------------------------------ */
// const featuredProjects = [
//   {
//     overline: 'Featured Project',
//     title: 'HokieAI Chatbot: AI Advisor for Virginia Tech Students',
//     description: `
//       <p>
//         Crafted an advanced AI advisor leveraging the MERN stack to revolutionize how students
//         access campus resources. Powered by extensively fine-tuned OpenAI technologies (ChatGPT 3.5 turbo, GPT-4, DALL-E),
//         it delivers personalized academic and wellness support.
//       </p>
//       <p>
//         Deep integration with Microsoft Calendar ensures efficient appointments and communications,
//         providing a seamless experience for students.
//       </p>
//     `,
//     tech: [
//       'MongoDB',
//       'Express.js',
//       'React',
//       'Node.js',
//       'Prompt Engineering',
//       'Model Fine-tuning',
//       'LLM Integration',
//       'HTML',
//       'CSS',
//       'JavaScript',
//       'API Integration',
//     ],
//     github: 'https://github.com/roshanrav/hokieai',
//     external: 'https://hokieai-demo-link.com',
//     cta: null,
//     slideshowImages: [hokieAIImage1, hokieAIImage2, hokieAIImage3],
//   },
//   {
//     overline: 'Featured Project',
//     title: 'DebugMaster AI: Your AI Debugger',
//     description: `
//       <p>
//         DebugMaster AI automates detection and resolution of code issues in real-time.
//         On new GitHub issues, it analyzes, debugs, and generates a PR with ~85% accuracy.
//       </p>
//       <p>
//         Integrates LLMs & Langchain, Kubernetes for scalability, MySQL & MongoDB for storage,
//         Redis and GraphQL for efficient data handling, and TensorFlow for predictive analytics.
//       </p>
//     `,
//     tech: [
//       'Kubernetes',
//       'MySQL',
//       'MongoDB',
//       'Express.js',
//       'React',
//       'Node.js',
//       'Langchain',
//       'LLM Integration',
//       'TensorFlow',
//       'Redis',
//       'GraphQL',
//       'Google Cloud',
//     ],
//     github: 'https://github.com/roshanrav/debugmaster',
//     external: null,
//     cta: null,
//     slideshowImages: [debugMasterImg],
//   },
//   {
//     overline: 'Featured Project',
//     title: 'NBA PRA Predictor',
//     description: `
//       <p>
//         An advanced analytics model predicting whether an NBA player's points, rebounds, and assists 
//         will be over or under a given line for the day.
//       </p>
//       <p>
//         Utilizes real-time NBA API data, extensive cleansing/restructuring, and hyperparameter tuning 
//         to achieve 71.3% accuracy.
//       </p>
//     `,
//     tech: [
//       'Python',
//       'Scikit-learn',
//       'Pandas',
//       'NumPy',
//       'Matplotlib',
//       'Data Visualization',
//     ],
//     github: null,
//     external: null,
//     cta: null,
//     slideshowImages: [nbaPredictorImg],
//   },
// ];

// /* ------------------------------------------
//  *  The main Featured component
//  * ------------------------------------------ */
// const Featured = () => {
//   const revealTitle = useRef(null);
//   const revealProjects = useRef([]);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     if (prefersReducedMotion) return;

//     // If you're using ScrollReveal:
//     sr.reveal(revealTitle.current, srConfig());
//     revealProjects.current.forEach((ref, i) => {
//       sr.reveal(ref, srConfig(i * 100));
//     });
//   }, [prefersReducedMotion]);

//   return (
//     <section id="projects">
//       <h2 className="numbered-heading" ref={revealTitle}>
//         Some Things I’ve Built
//       </h2>

//       <StyledProjectsGrid>
//         {featuredProjects.map((project, i) => {
//           const {
//             overline,
//             title,
//             description,
//             tech,
//             github,
//             external,
//             cta,
//             slideshowImages,
//           } = project;

//           return (
//             <StyledProject key={title} ref={el => (revealProjects.current[i] = el)}>
//               {/* Project Text */}
//               <div className="project-content">
//                 <div>
//                   {overline && <p className="project-overline">{overline}</p>}

//                   <h3 className="project-title">
//                     {external ? (
//                       <a href={external} target="_blank" rel="noreferrer">
//                         {title}
//                       </a>
//                     ) : (
//                       title
//                     )}
//                   </h3>

//                   {description && (
//                     <div
//                       className="project-description"
//                       dangerouslySetInnerHTML={{ __html: description }}
//                     />
//                   )}

//                   {tech?.length > 0 && (
//                     <ul className="project-tech-list">
//                       {tech.map((item, idx) => (
//                         <li key={idx}>{item}</li>
//                       ))}
//                     </ul>
//                   )}

//                   <div className="project-links">
//                     {cta && (
//                       <a href={cta} aria-label="Course Link" className="cta">
//                         Learn More
//                       </a>
//                     )}
//                     {github && (
//                       <a href={github} aria-label="GitHub Link" target="_blank" rel="noreferrer">
//                         <Icon name="GitHub" />
//                       </a>
//                     )}
//                     {external && !cta && (
//                       <a
//                         href={external}
//                         aria-label="External Link"
//                         className="external"
//                         target="_blank"
//                         rel="noreferrer">
//                         <Icon name="External" />
//                       </a>
//                     )}
//                   </div>
//                 </div>
//               </div>

//               {/* Project Image / Slideshow */}
//               <div className="project-image">
//                 <a
//                   href={external || github || '#'}
//                   target="_blank"
//                   rel="noreferrer">
//                   <Slideshow images={slideshowImages} />
//                 </a>
//               </div>
//             </StyledProject>
//           );
//         })}
//       </StyledProjectsGrid>
//     </section>
//   );
// };

// export default Featured;
