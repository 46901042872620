// import React, { useState, useEffect } from 'react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import styled from 'styled-components';
// import { navDelay, loaderDelay } from '@utils';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledHeroSection = styled.section`
//   ${({ theme }) => theme.mixins.flexCenter};
//   flex-direction: column;
//   align-items: flex-start;
//   min-height: 100vh;
//   height: 100vh;
//   padding: 0;

//   @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
//     height: auto;
//     padding-top: var(--nav-height);
//   }

//   h1 {
//     margin: 0 0 30px 4px;
//     color: var(--green);
//     font-family: var(--font-mono);
//     font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
//     font-weight: 400;

//     @media (max-width: 480px) {
//       margin: 0 0 20px 2px;
//     }
//   }

//   h3 {
//     margin-top: 5px;
//     color: var(--slate);
//     line-height: 0.9;
//   }

//   p {
//     margin: 20px 0 0;
//     max-width: 540px;
//   }

//   .email-link {
//     ${({ theme }) => theme.mixins.bigButton};
//     margin-top: 50px;
//   }
// `;

// const Hero = () => {
//   const [isMounted, setIsMounted] = useState(false);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     if (prefersReducedMotion) {
//       return;
//     }

//     const timeout = setTimeout(() => setIsMounted(true), navDelay);
//     return () => clearTimeout(timeout);
//   }, []);

//   const one = <h1>Hi, my name is</h1>;
//   const two = <h2 className="big-heading">Roshan Ravindran.</h2>;
//   const three = <h3 className="big-heading">I build things for the web.</h3>;
//   const four = (
//     <>
//       <p>
//         I’m a software engineer specializing in building (and occasionally designing) exceptional
//         digital experiences. Currently, I’m focused on building accessible, human-centered products
//         at{' '}
//         <a href="https://upstatement.com/" target="_blank" rel="noreferrer">
//           Upstatement
//         </a>
//         .
//       </p>
//     </>
//   );
//   const five = (
//     <a
//       className="email-link"
//       href="https://www.newline.co/courses/build-a-spotify-connected-app"
//       target="_blank"
//       rel="noreferrer">
//       Check out my course!
//     </a>
//   );

//   const items = [one, two, three, four, five];

//   return (
//     <StyledHeroSection>
//       {prefersReducedMotion ? (
//         <>
//           {items.map((item, i) => (
//             <div key={i}>{item}</div>
//           ))}
//         </>
//       ) : (
//         <TransitionGroup component={null}>
//           {isMounted &&
//             items.map((item, i) => (
//               <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
//                 <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
//               </CSSTransition>
//             ))}
//         </TransitionGroup>
//       )}
//     </StyledHeroSection>
//   );
// };

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 220px 0px 100px;
  margin-bottom: 70px;
  margin-left: 100px;
  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 90px;

    @media (max-width: 400px) {
      display: block;
    }
  }
`;

const StyledTextColumn = styled.div`
  h1 {
    margin: 0 0 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-weight: 400;
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  }

  h2.big-heading {
    font-size: clamp(40px, 8vw, 80px);
    margin: 20 20 5px 0;
    font-weight: 600;
  }

  h3.big-heading {
    font-size: clamp(30px, 6vw, 60px);
    margin: 10px 0 20px;
    font-weight: 500;
    color: var(--slate);
  }

  p {
    max-width: 540px;
    margin-bottom: 25px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
  }
`;

const StyledPicColumn = styled.div`
  max-width: 300px;
  margin: 50px auto 0;

  @media (min-width: 769px) {
    margin: 0; /* only have margin top on mobile */
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    position: relative;
    border-radius: var(--border-radius);
    background-color: var(--green);
    transition: var(--transition);

    &:hover,
    &:focus {
      transform: translate(-4px, -4px);
      &:after {
        transform: translate(8px, 8px);
      }
      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      display: block;
      width: 100%;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: var(--border-radius);
      width: 100%;
      height: 100%;
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    // If user prefers reduced motion, avoid transitions
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, [prefersReducedMotion]);

  // Text items that will fade in
  const items = [
    <h1 key="0">Hi, I'm</h1>,
    <h2 key="1" className="big-heading">
      Roshan Ravindran,
    </h2>,
    <h3 key="1" className="big-heading">
      and I enjoy creating practical solutions.
    </h3>,
    <p key="3">
       I specialize in software development, generative AI, and machine learning, creating user-centric applications and building ML models for AI-powered solutions.
    </p>,
    <a
      key="4"
      className="email-link"
      href="/resume.pdf"
      target="_blank"
      rel="noreferrer">
      Find my resume here!
    </a>,
  ];
  const ResumeLink = (
    <a className="resume-button" href="/resume.pdf" target="_blank" rel="noopener noreferrer">
      Resume
    </a>
  );

  return (
    <StyledHeroSection id="hero">
      <div className="inner">
        {/* LEFT COLUMN (TEXT) */}
        <StyledTextColumn>
          {prefersReducedMotion ? (
            // If reduced motion is preferred, just render text
            <>
              {items.map(item => item)}
            </>
          ) : (
            // Otherwise, fade them in with TransitionGroup
            <TransitionGroup component={null}>
              {isMounted &&
                items.map((item, i) => (
                  <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                    {/* Increase delay step by step */}
                    <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
                  </CSSTransition>
                ))}
            </TransitionGroup>
          )}
        </StyledTextColumn>

        {/* RIGHT COLUMN (IMAGE) */}
        <StyledPicColumn>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/profile-pic.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Profile Pic"
            />
          </div>
        </StyledPicColumn>
      </div>
    </StyledHeroSection>
  );
};

export default Hero;
