import React from 'react';
import RRLogo from './../../images/logorr.png'; // Adjust the path based on your project structure

const IconHex = () => (
  <div style={styles.container}>
    <img
      src={RRLogo}
      alt="Roshan Ravindran Logo"
      style={styles.image}
    />
  </div>
);

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px', // Adjust size as needed
    height: '50px',
    background: 'linear-gradient(135deg, #8e8275, #332e28)', // Gradient background d1ceca
    borderRadius: '10px', // Rounded corners
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Subtle shadow
    padding: '10px',
    transition: 'transform 0.3s ease-in-out',
  },
  image: {
    width: '80px',
    height: '40px',
    filter: 'drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.2))', // White glow effect
    transition: 'transform 0.3s ease-in-out',
  },
};

// Add hover effect using JS
styles.container[':hover'] = {
  transform: 'scale(1.1)', // Slight zoom-in effect
};

export default IconHex;


