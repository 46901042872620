import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Define keyframe animations
const StyledLoader = styled.div`
  /* Background pulse animation */
  @keyframes backgroundPulse {
    0% {
      background: var(--dark-navy);
    }
    50% {
      background: var(--navy);
    }
    100% {
      background: var(--dark-navy);
    }
  }

  /* Cube rotation animation */
  @keyframes rotateCube {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }
    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }

  /* Text fade-in animation */
  @keyframes fadeInText {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Text pulse animation */
  @keyframes pulseText {
    0% { transform: scale(1); }
    100% { transform: scale(1.05); }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: backgroundPulse 10s infinite alternate;
  z-index: 999;
  opacity: ${(props) => (props.isMounted ? 1 : 0)};
  transition: opacity 0.5s ease-in;

  .cube {
    width: 70px;
    height: 70px;
    position: relative;
    transform-style: preserve-3d;
    animation: rotateCube 3s infinite ease-in-out;
    margin-bottom: 50px;
  }

  .cube div {
    position: absolute;
    width: 70px;
    height: 70px;
    background: #ded4c0;
    border: 2px solid #6b6458;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  .front  { transform: translateZ(35px); }
  .back   { transform: rotateY(180deg) translateZ(35px); }
  .left   { transform: rotateY(-90deg) translateZ(35px); }
  .right  { transform: rotateY(90deg) translateZ(35px); }
  .top    { transform: rotateX(90deg) translateZ(35px); }
  .bottom { transform: rotateX(-90deg) translateZ(35px); }

  .loader-text {
    font-size: 1.4rem;
    color: #fff;
    font-family: var(--font-mono);
    text-align: center;
    letter-spacing: 2px;
    animation: fadeInText 1.5s ease-in-out, pulseText 2s infinite alternate;
  }
`;

const Loader = ({ finishLoading }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const mountTimeout = setTimeout(() => {
      setIsMounted(true);
    }, 300);

    const finishTimeout = setTimeout(() => {
      finishLoading();
    }, 3000);

    return () => {
      clearTimeout(mountTimeout);
      clearTimeout(finishTimeout);
    };
  }, [finishLoading]);

  return (
    <StyledLoader isMounted={isMounted}>
      <Helmet>
        <link 
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" 
          rel="stylesheet"
        />
      </Helmet>
      <div className="cube">
        <div className="front" />
        <div className="back" />
        <div className="left" />
        <div className="right" />
        <div className="top" />
        <div className="bottom" />
      </div>
      <div className="loader-text">Loading, please wait...</div>
    </StyledLoader>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

export default Loader;
