import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
    ${({ theme }) => theme.mixins.bigButton};
  max-width: 80%;
  margin: 0 auto 100px;
  padding: 50px;
  text-align: center;
  background-color: var(--dark-navy);
  border-radius: 30%;
  box-shadow: var(--box-shadow);

  @media (max-width: 768px) {
    margin: 0 auto 50px;
    padding: 40px;
  }

  .headline {
    font-size: clamp(24px, 5vw, 32px);
    color: var(--green);
    font-family: var(--font-mono);
    margin-bottom: 20px;
  }

  .main-title {
    font-size: clamp(32px, 6vw, 48px);
    color: var(--white);
    margin-bottom: 30px;
  }

  .description {
    font-size: var(--fz-lg);
    color: var(--light-slate);
    line-height: 1.5;
    margin-bottom: 40px;
  }

  .email-button {
    ${({ theme }) => theme.mixins.bigButton};
    color: var(--dark-navy);
    background-color: var(--green);
    &:hover {
      background-color: var(--navy);
      color:var(--white);
    }
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>

      <h2 className="main-title">Let’s Connect</h2>

      <p className="description">
        I’m always excited to connect with like-minded individuals, whether it’s to brainstorm 
        new ideas, collaborate on projects, or simply chat about technology and innovation. 
        Don’t hesitate to reach out — I’d love to hear from you!


      </p>

      <a className="email-button" href="mailto:roshan14@vt.edu">
        Contact Me
      </a>
    </StyledContactSection>
  );
};

export default Contact;
