import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 50"
    width="200"
    height="50"
  >
    <title>Roshan Ravindran</title>
    <text
      x="50%"
      y="50%"
      dominantBaseline="middle"
      textAnchor="middle"
      fontSize="24"
      fontWeight="bold"
      fill="currentColor"
      fontFamily="Arial, sans-serif"
    >
      Roshan Ravindran
    </text>
  </svg>
);

export default IconLoader;
