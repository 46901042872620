// import React, { useState, useEffect, useRef } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import { CSSTransition } from 'react-transition-group';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import { KEY_CODES } from '@utils';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledJobsSection = styled.section`
//   max-width: 900px;

//   .inner {
//     display: flex;

//     @media (max-width: 600px) {
//       display: block;
//     }

//     // Prevent container from jumping
//     @media (min-width: 700px) {
//       min-height: 340px;
//     }
//   }
// `;

// const StyledTabList = styled.div`
//   position: relative;
//   z-index: 3;
//   width: max-content;
//   padding: 0;
//   margin: 0;
//   list-style: none;

//   @media (max-width: 600px) {
//     display: flex;
//     overflow-x: auto;
//     width: calc(100% + 100px);
//     padding-left: 50px;
//     margin-left: -50px;
//     margin-bottom: 30px;
//   }
//   @media (max-width: 480px) {
//     width: calc(100% + 50px);
//     padding-left: 25px;
//     margin-left: -25px;
//   }

//   li {
//     &:first-of-type {
//       @media (max-width: 600px) {
//         margin-left: 50px;
//       }
//       @media (max-width: 480px) {
//         margin-left: 25px;
//       }
//     }
//     &:last-of-type {
//       @media (max-width: 600px) {
//         padding-right: 50px;
//       }
//       @media (max-width: 480px) {
//         padding-right: 25px;
//       }
//     }
//   }
// `;

// const StyledTabButton = styled.button`
//   ${({ theme }) => theme.mixins.link};
//   display: flex;
//   align-items: center;
//   /* Keep the older style but set a fixed width: */
//   width: 200px;
//   height: var(--tab-height);
//   padding: 0 20px 2px;
//   border-left: 2px solid var(--lightest-navy);
//   background-color: transparent;
//   color: ${({ isActive }) => (isActive ? 'var(--green)' : 'var(--slate)')};
//   font-family: var(--font-mono);
//   font-size: var(--fz-xs);
//   text-align: left;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   @media (max-width: 768px) {
//     padding: 0 15px 2px;
//   }
//   @media (max-width: 600px) {
//     ${({ theme }) => theme.mixins.flexCenter};
//     /* On small screens, you can keep this or reduce further: */
//     width: 120px;
//     padding: 0 15px;
//     border-left: 0;
//     border-bottom: 2px solid var(--lightest-navy);
//     text-align: center;
//   }

//   &:hover,
//   &:focus {
//     background-color: var(--light-navy);
//   }
// `;


// const StyledHighlight = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 10;
//   width: 2px;
//   height: var(--tab-height);
//   border-radius: var(--border-radius);
//   background: var(--green);
//   transform: translateY(calc(${({ activeTabId }) => activeTabId} * var(--tab-height)));
//   transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
//   transition-delay: 0.1s;

//   @media (max-width: 600px) {
//     top: auto;
//     bottom: 0;
//     width: 100%;
//     max-width: var(--tab-width);
//     height: 2px;
//     margin-left: 50px;
//     transform: translateX(calc(${({ activeTabId }) => activeTabId} * var(--tab-width)));
//   }
//   @media (max-width: 480px) {
//     margin-left: 25px;
//   }
// `;

// const StyledTabPanels = styled.div`
//   position: relative;
//   width: 100%;
//   margin-left: 20px;

//   @media (max-width: 600px) {
//     margin-left: 0;
//   }
// `;

// const StyledTabPanel = styled.div`
//   width: 100%;
//   height: auto;
//   padding: 10px 5px;

//   ul {
//     ${({ theme }) => theme.mixins.fancyList};
//   }

//   h3 {
//     margin-bottom: 2px;
//     font-size: var(--fz-xxl);
//     font-weight: 500;
//     line-height: 1.3;

//     .company {
//       color: var(--green);
//     }
//   }

//   .range {
//     margin-bottom: 25px;
//     color: var(--light-slate);
//     font-family: var(--font-mono);
//     font-size: var(--fz-xs);
//   }
// `;

// const Jobs = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       jobs: allMarkdownRemark(
//         filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
//         sort: { fields: [frontmatter___date], order: DESC }
//       ) {
//         edges {
//           node {
//             frontmatter {
//               title
//               company
//               location
//               range
//               url
//             }
//             html
//           }
//         }
//       }
//     }
//   `);

//   const jobsData = data.jobs.edges;

//   const [activeTabId, setActiveTabId] = useState(0);
//   const [tabFocus, setTabFocus] = useState(null);
//   const tabs = useRef([]);
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     if (prefersReducedMotion) {
//       return;
//     }

//     sr.reveal(revealContainer.current, srConfig());
//   }, []);

//   const focusTab = () => {
//     if (tabs.current[tabFocus]) {
//       tabs.current[tabFocus].focus();
//       return;
//     }
//     // If we're at the end, go to the start
//     if (tabFocus >= tabs.current.length) {
//       setTabFocus(0);
//     }
//     // If we're at the start, move to the end
//     if (tabFocus < 0) {
//       setTabFocus(tabs.current.length - 1);
//     }
//   };

//   // Only re-run the effect if tabFocus changes
//   useEffect(() => focusTab(), [tabFocus]);

//   // Focus on tabs when using up & down arrow keys
//   const onKeyDown = e => {
//     switch (e.key) {
//       case KEY_CODES.ARROW_UP: {
//         e.preventDefault();
//         setTabFocus(tabFocus - 1);
//         break;
//       }

//       case KEY_CODES.ARROW_DOWN: {
//         e.preventDefault();
//         setTabFocus(tabFocus + 1);
//         break;
//       }

//       default: {
//         break;
//       }
//     }
//   };

//   return (
//     <StyledJobsSection id="jobs" ref={revealContainer}>
//       <h2 className="numbered-heading">Where I’ve Worked</h2>

//       <div className="inner">
//         <StyledTabList role="tablist" aria-label="Job tabs" onKeyDown={e => onKeyDown(e)}>
//           {jobsData &&
//             jobsData.map(({ node }, i) => {
//               const { company } = node.frontmatter;
//               return (
//                 <StyledTabButton
//                   key={i}
//                   isActive={activeTabId === i}
//                   onClick={() => setActiveTabId(i)}
//                   ref={el => (tabs.current[i] = el)}
//                   id={`tab-${i}`}
//                   role="tab"
//                   tabIndex={activeTabId === i ? '0' : '-1'}
//                   aria-selected={activeTabId === i ? true : false}
//                   aria-controls={`panel-${i}`}>
//                   <span>{company}</span>
//                 </StyledTabButton>
//               );
//             })}
//           <StyledHighlight activeTabId={activeTabId} />
//         </StyledTabList>

//         <StyledTabPanels>
//           {jobsData &&
//             jobsData.map(({ node }, i) => {
//               const { frontmatter, html } = node;
//               const { title, url, company, range } = frontmatter;

//               return (
//                 <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
//                   <StyledTabPanel
//                     id={`panel-${i}`}
//                     role="tabpanel"
//                     tabIndex={activeTabId === i ? '0' : '-1'}
//                     aria-labelledby={`tab-${i}`}
//                     aria-hidden={activeTabId !== i}
//                     hidden={activeTabId !== i}>
//                     <h3>
//                       <span>{title}</span>
//                       <span className="company">
//                         &nbsp;@&nbsp;
//                         <a href={url} className="inline-link">
//                           {company}
//                         </a>
//                       </span>
//                     </h3>

//                     <p className="range">{range}</p>

//                     <div dangerouslySetInnerHTML={{ __html: html }} />
//                   </StyledTabPanel>
//                 </CSSTransition>
//               );
//             })}
//         </StyledTabPanels>
//       </div>
//     </StyledJobsSection>
//   );
// };

// export default Jobs;
// import React, { useState, useEffect, useRef } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledJobsSection = styled.section`
//   max-width: 1000px;
//   margin: 0 auto;
//   padding: 100px 20px; /* Added horizontal padding for better spacing */
//   margin-top: 200px;

//   h2 {
//     text-align: center;
//     margin-bottom: 50px;
//     font-size: var(--fz-xxl); /* Increased font size */
//   }
// `;

// const StyledJobDetails = styled.div`
//   background-color: var(--light-navy); /* Added card background */
//   padding: 30px 40px; /* Increased padding */
//   border-radius: 12px; /* Rounded corners for card effect */
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
//   margin-bottom: 40px; /* Space below the card */

//   /* Fixed dimensions */
//   width: 100%; /* Full width of the container */
//   max-width: 1000px; /* Maximum width */
//   height: 355px; /* Fixed height */
//   overflow-y: auto; /* Scroll if content exceeds height */

//   /* Optional: Customize scrollbar for better aesthetics */
//   &::-webkit-scrollbar {
//     width: 8px;
//   }

//   &::-webkit-scrollbar-track {
//     background: var(--lightest-navy);
//     border-radius: 4px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--green);
//     border-radius: 4px;
//   }

//   .job-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: baseline;
//     margin-bottom: 2px;

//     h3 {
//       margin: 0;
//       font-size: var(--fz-2xl); /* Increased font size */
//       font-weight: 2000; /* Bolder text */
//       color: var(--lightest-slate);
//     }

//     .range {
//       font-size: var(--fz-md); /* Increased font size */
//       font-family: var(--font-mono);
//       color: #D3D3D3;
//     }
//   }

//   .company {
//     font-size: var(--fz-xl); /* Increased font size */
//     color: #D3D3D3;
//     margin-bottom: 15px;
//     font-weight: 3000; /* Slightly bolder */
//   }

//   .job-description {
//     font-weight:500;
//     line-height: 1.8; /* Increased line height for readability */
//     font-size: var(--fz-lg); /* Increased font size */
//     color: var(--white);

//     ul {
//       list-style-type: disc;
//       list-style-position: inside;
//       margin-top: 1.5em; /* Increased margin */
//       padding-left: 20px; /* Added padding for better list spacing */
//     }
//   }
// `;

// const TimelineNav = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 20px; /* Changed from margin-bottom to margin-top */
//   gap: 20px; /* Space between arrows and marker line */

//   .arrow {
//     font-size: var(--fz-2xl); /* Increased font size */
//     cursor: pointer;
//     color: var(--green);
//     user-select: none;
//     transition: color 0.2s ease;

//     &:hover {
//       color: var(--lightest-slate);
//     }

//     /* Disable the arrow if there's no more jobs in that direction */
//     &.disabled {
//       opacity: 0.3;
//       cursor: not-allowed;
//     }
//   }
// `;

// const MarkerLine = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
// `;

// const Marker = styled.div`
//   width: 16px; /* Increased size */
//   height: 16px; /* Increased size */
//   border-radius: 50%;
//   background-color: var(--lightest-navy);
//   border: 2px solid var(--light-slate);
//   cursor: pointer;
//   transition: background-color 0.2s, border 0.2s;

//   &.active {
//     background-color: var(--green);
//     border-color: var(--green);
//   }

//   &:hover {
//     border-color: var(--green);
//   }
// `;

// const Jobs = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       jobs: allMarkdownRemark(
//         filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
//         sort: { fields: [frontmatter___endDate], order: DESC }
//       ) {
//         edges {
//           node {
//             frontmatter {
//               title
//               company
//               range
//               endDate
//             }
//             html
//           }
//         }
//       }
//     }
//   `);

//   const jobsData = data.jobs.edges;
//   const [activeIndex, setActiveIndex] = useState(0);
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   // Scroll reveal
//   useEffect(() => {
//     if (!prefersReducedMotion) {
//       sr.reveal(revealContainer.current, srConfig());
//     }
//   }, [prefersReducedMotion]);

//   // Navigation handlers
//   const goPrev = () => {
//     if (activeIndex > 0) {
//       setActiveIndex(activeIndex - 1);
//     }
//   };

//   const goNext = () => {
//     if (activeIndex < jobsData.length - 1) {
//       setActiveIndex(activeIndex + 1);
//     }
//   };

//   if (!jobsData || jobsData.length === 0) {
//     return null;
//   }

//   // Current (active) job
//   const { frontmatter, html } = jobsData[activeIndex].node;
//   const { title, company, range } = frontmatter;

//   return (
//     <StyledJobsSection id="jobs" ref={revealContainer}>
//       <h2 className="numbered-heading">Where I’ve Worked</h2>

//       {/* Active Job Details */}
//       <StyledJobDetails>
//         <div className="job-header">
//           <h3>{title}</h3>
//           <span className="range">{range}</span>
//         </div>
//         <div className="company">{company}</div>
//         <div
//           className="job-description"
//           dangerouslySetInnerHTML={{ __html: html }}
//         />
//       </StyledJobDetails>

//       {/* Timeline Navigation */}
//       <TimelineNav>
//         {/* Left Arrow */}
//         <div
//           className={`arrow ${activeIndex === 0 ? 'disabled' : ''}`}
//           onClick={goPrev}
//           onKeyDown={(e) => e.key === 'Enter' && goPrev()}
//           role="button"
//           tabIndex={0}
//           aria-label="Previous Job"
//         >
//           &#8592;
//         </div>

//         {/* Markers */}
//         <MarkerLine>
//           {jobsData.map((job, i) => (
//             <Marker
//               key={i}
//               className={i === activeIndex ? 'active' : ''}
//               onClick={() => setActiveIndex(i)}
//               onKeyDown={(e) => e.key === 'Enter' && setActiveIndex(i)}
//               role="button"
//               tabIndex={0}
//               aria-label={`Job ${i + 1}`}
//             />
//           ))}
//         </MarkerLine>

//         {/* Right Arrow */}
//         <div
//           className={`arrow ${
//             activeIndex === jobsData.length - 1 ? 'disabled' : ''
//           }`}
//           onClick={goNext}
//           onKeyDown={(e) => e.key === 'Enter' && goNext()}
//           role="button"
//           tabIndex={0}
//           aria-label="Next Job"
//         >
//           &#8594;
//         </div>
//       </TimelineNav>
//     </StyledJobsSection>
//   );
// };

// export default Jobs;
import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { useSwipeable } from 'react-swipeable'; 

const StyledJobsSection = styled.section`
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 20px;
  margin-top: 70px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: var(--fz-heading);
  }
`;

const StyledJobDetails = styled.div`
  background-color: var(--light-navy);
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  /* Fixed dimensions */
  width: 100%;
  max-width: 1000px;
  height: 355px;
  overflow-y: auto;

  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--lightest-navy);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--green);
    border-radius: 4px;
  }

  /* Swipe cursor */
  cursor: grab;

  .job-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 2px;

    h3 {
      margin: 0;
      font-size: var(fz-heading); /* Adjusted to a valid weight */
      color: var(--white);
      font-weight: 400px;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--navy);
          }

    .range {
      font-size: var(--fz-md);
      font-family: var(--font-mono);
      color: #D3D3D3;
    }
  }

  .company {
    font-size: var(--fz-xl);
    color: #D3D3D3;
    margin-bottom: 15px;
    font-weight: 500;

  }

  .job-description {
    font-weight: 500;
    line-height: 1.8;
    font-size: var(--fz-lg);
    color: var(--white);

    ul {
      list-style-type: disc;
      list-style-position: inside;
      margin-top: 1.5em;
      padding-left: 20px;
    }
  }

  /* Change cursor on active drag */
  &.swiping {
    cursor: grabbing;
  }
`;

const TimelineNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;

  .arrow {
    font-size: var(--fz-2xl);
    cursor: pointer;
    color: var(--green);
    user-select: none;
    transition: color 0.2s ease;

    &:hover {
      color: var(--lightest-slate);
    }

    /* Disable the arrow if there's no more jobs in that direction */
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;

const MarkerLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--lightest-navy);
  border: 2px solid var(--light-slate);
  cursor: pointer;
  transition: background-color 0.2s, border 0.2s;

  &.active {
    background-color: var(--green);
    border-color: var(--green);
  }

  &:hover {
    border-color: var(--green);
  }
`;

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
        sort: { fields: [frontmatter___endDate], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              range
              endDate
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;
  const [activeIndex, setActiveIndex] = useState(0);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  // Scroll reveal
  useEffect(() => {
    if (!prefersReducedMotion) {
      sr.reveal(revealContainer.current, srConfig());
    }
  }, [prefersReducedMotion]);

  // Navigation handlers
  const goPrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const goNext = () => {
    if (activeIndex < jobsData.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  if (!jobsData || jobsData.length === 0) {
    return null;
  }

  // Current (active) job
  const { frontmatter, html } = jobsData[activeIndex].node;
  const { title, company, range } = frontmatter;

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => goNext(),
    onSwipedRight: () => goPrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allow swipe with mouse (optional)
  });

  return (
    <StyledJobsSection id="jobs" ref={revealContainer}>
      <h2 className="numbered-heading">Job Experience</h2>

      {/* Active Job Details with Swipe Handlers */}
      <StyledJobDetails {...handlers}>
        <div className="job-header">
          <h3>{title}</h3>
          <span className="range">{range}</span>
        </div>
        <div className="company">{company}</div>
        <div
          className="job-description"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </StyledJobDetails>

      {/* Timeline Navigation */}
      <TimelineNav>
        {/* Left Arrow */}
        <div
          className={`arrow ${activeIndex === 0 ? 'disabled' : ''}`}
          onClick={goPrev}
          onKeyDown={(e) => e.key === 'Enter' && goPrev()}
          role="button"
          tabIndex={0}
          aria-label="Previous Job"
        >
          &#8592;
        </div>

        {/* Markers */}
        <MarkerLine>
          {jobsData.map((job, i) => (
            <Marker
              key={i}
              className={i === activeIndex ? 'active' : ''}
              onClick={() => setActiveIndex(i)}
              onKeyDown={(e) => e.key === 'Enter' && setActiveIndex(i)}
              role="button"
              tabIndex={0}
              aria-label={`Job ${i + 1}`}
            />
          ))}
        </MarkerLine>

        {/* Right Arrow */}
        <div
          className={`arrow ${
            activeIndex === jobsData.length - 1 ? 'disabled' : ''
          }`}
          onClick={goNext}
          onKeyDown={(e) => e.key === 'Enter' && goNext()}
          role="button"
          tabIndex={0}
          aria-label="Next Job"
        >
          &#8594;
        </div>
      </TimelineNav>
    </StyledJobsSection>
  );
};

export default Jobs;
