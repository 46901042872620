// import React, { useEffect, useRef } from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledAboutSection = styled.section`
//   max-width: 900px;

//   .inner {
//     display: grid;
//     grid-template-columns: 3fr 2fr;
//     grid-gap: 50px;

//     @media (max-width: 768px) {
//       display: block;
//     }
//   }
// `;
// const StyledText = styled.div`
//   ul.skills-list {
//     display: grid;
//     grid-template-columns: repeat(2, minmax(140px, 200px));
//     grid-gap: 0 10px;
//     padding: 0;
//     margin: 20px 0 0 0;
//     overflow: hidden;
//     list-style: none;

//     li {
//       position: relative;
//       margin-bottom: 10px;
//       padding-left: 20px;
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);

//       &:before {
//         content: '▹';
//         position: absolute;
//         left: 0;
//         color: var(--green);
//         font-size: var(--fz-sm);
//         line-height: 12px;
//       }
//     }
//   }
// `;
// const StyledPic = styled.div`
//   position: relative;
//   max-width: 300px;

//   @media (max-width: 768px) {
//     margin: 50px auto 0;
//     width: 70%;
//   }

//   .wrapper {
//     ${({ theme }) => theme.mixins.boxShadow};
//     display: block;
//     position: relative;
//     width: 100%;
//     border-radius: var(--border-radius);
//     background-color: var(--green);

//     &:hover,
//     &:focus {
//       outline: 0;
//       transform: translate(-4px, -4px);

//       &:after {
//         transform: translate(8px, 8px);
//       }

//       .img {
//         filter: none;
//         mix-blend-mode: normal;
//       }
//     }

//     .img {
//       position: relative;
//       border-radius: var(--border-radius);
//       mix-blend-mode: multiply;
//       filter: grayscale(100%) contrast(1);
//       transition: var(--transition);
//     }

//     &:before,
//     &:after {
//       content: '';
//       display: block;
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       border-radius: var(--border-radius);
//       transition: var(--transition);
//     }

//     &:before {
//       top: 0;
//       left: 0;
//       background-color: var(--navy);
//       mix-blend-mode: screen;
//     }

//     &:after {
//       border: 2px solid var(--green);
//       top: 14px;
//       left: 14px;
//       z-index: -1;
//     }
//   }
// `;

// const About = () => {
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     if (prefersReducedMotion) {
//       return;
//     }

//     sr.reveal(revealContainer.current, srConfig());
//   }, []);

//   const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Eleventy', 'Node.js', 'WordPress'];

//   return (
//     <StyledAboutSection id="about" ref={revealContainer}>
//       <h2 className="numbered-heading">About Me</h2>

//       <div className="inner">
//         <StyledText>
//           <div>
//             <p>
//               Hello! My name is Brittany and I enjoy creating things that live on the internet. My
//               interest in web development started back in 2012 when I decided to try editing custom
//               Tumblr themes — turns out hacking together a custom reblog button taught me a lot
//               about HTML &amp; CSS!
//             </p>

//             <p>
//               Fast-forward to today, and I’ve had the privilege of working at{' '}
//               <a href="https://us.mullenlowe.com/">an advertising agency</a>,{' '}
//               <a href="https://starry.com/">a start-up</a>,{' '}
//               <a href="https://www.apple.com/">a huge corporation</a>, and{' '}
//               <a href="https://scout.camd.northeastern.edu/">a student-led design studio</a>. My
//               main focus these days is building accessible, inclusive products and digital
//               experiences at <a href="https://upstatement.com/">Upstatement</a> for a variety of
//               clients.
//             </p>

//             <p>
//               I also recently{' '}
//               <a href="https://www.newline.co/courses/build-a-spotify-connected-app">
//                 launched a course
//               </a>{' '}
//               that covers everything you need to build a web app with the Spotify API using Node
//               &amp; React.
//             </p>

//             <p>Here are a few technologies I’ve been working with recently:</p>
//           </div>

//           <ul className="skills-list">
//             {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
//           </ul>
//         </StyledText>

//         <StyledPic>
//           <div className="wrapper">
//             <StaticImage
//               className="img"
//               src="../../images/me.jpg"
//               width={500}
//               quality={95}
//               formats={['AUTO', 'WEBP', 'AVIF']}
//               alt="Headshot"
//             />
//           </div>
//         </StyledPic>
//       </div>
//     </StyledAboutSection>
//   );
// };

// export default About;

// import React, { useEffect, useRef, useState } from 'react';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledAboutSection = styled.section`
//   max-width: 900px;

//   .inner {
//     display: grid;
//     grid-template-columns: 3fr 2fr;
//     grid-gap: 50px;

//     @media (max-width: 768px) {
//       display: block;
//     }
//   }
// `;
// const StyledText = styled.div`
//   ul.skills-list {
//     display: grid;
//     grid-template-columns: repeat(2, minmax(140px, 200px));
//     grid-gap: 0 10px;
//     padding: 0;
//     margin: 20px 0 0 0;
//     overflow: hidden;
//     list-style: none;

//     li {
//       position: relative;
//       margin-bottom: 10px;
//       padding-left: 20px;
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);

//       &:before {
//         content: '▹';
//         position: absolute;
//         left: 0;
//         color: var(--green);
//         font-size: var(--fz-sm);
//         line-height: 12px;
//       }
//     }
//   }
// `;
// const StyledBox = styled.div`
//   position: relative;
//   max-width: 300px;

//   @media (max-width: 768px) {
//     margin: 50px auto 0;
//     width: 70%;
//   }

//   .textbox {
//     ${({ theme }) => theme.mixins.boxShadow};
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     width: 100%;
//     border-radius: var(--border-radius);
//     background-color: var(--light-navy);
//     padding: 20px;

//     textarea {
//       width: 100%;
//       height: 100px;
//       margin-bottom: 10px;
//       border-radius: var(--border-radius);
//       border: 1px solid var(--green);
//       padding: 10px;
//       font-size: var(--fz-sm);
//       font-family: var(--font-mono);
//     }

//     button {
//       background-color: var(--green);
//       color: var(--navy);
//       border: none;
//       padding: 10px;
//       border-radius: var(--border-radius);
//       cursor: pointer;
//       font-family: var(--font-mono);
//       font-size: var(--fz-sm);

//       &:hover {
//         background-color: var(--light-green);
//       }
//     }

//     .response {
//       margin-top: 10px;
//       font-size: var(--fz-sm);
//       color: var(--green);
//     }
//   }
// `;

// const About = () => {
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();
//   const [jobDescription, setJobDescription] = useState('');
//   const [response, setResponse] = useState('');

//   useEffect(() => {
//     if (prefersReducedMotion) {
//       return;
//     }

//     if (revealContainer.current) {
//       sr.reveal(revealContainer.current, srConfig());
//     }
//   }, [prefersReducedMotion]);

//   const handleAnalyzeJob = async () => {
//     try {
//       const url = 'https://api.groq.com/openai/v1/chat/completions';

//       const headers = {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer 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`, // Replace YOUR_API_KEY with your actual API key
//       };

//       const body = {
//         model: 'llama-3.3-70b-versatile', // Replace with the desired model ID
//         messages: [
//           {
//             role: 'system',
//             content: 'You are a helpful assistant.',
//           },
//           {
//             role: 'user',
//             content: `Analyze the following job description and explain how Roshan fits into the role:\n\n${jobDescription}`,
//           },
//         ],
//         max_completion_tokens: 256,
//         temperature: 0.7,
//         top_p: 1,
//       };

//       const response = await fetch(url, {
//         method: 'POST',
//         headers,
//         body: JSON.stringify(body),
//       });

//       if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setResponse(data.choices[0].message.content);
//     } catch (error) {
//       setResponse('Error analyzing the job description. Please try again.');
//       console.error(error);
//     }
//   };

//   const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Eleventy', 'Node.js', 'WordPress'];

//   return (
//     <StyledAboutSection id="about" ref={revealContainer}>
//       <h2 className="numbered-heading">About Me</h2>

//       <div className="inner">
//         <StyledText>
//           <div>
//             <p>
//               Hello! My name is Roshan, and I enjoy creating things that live on the internet. My
//               main focus these days is building accessible, inclusive products and digital
//               experiences for a variety of clients.
//             </p>

//             <p>Here are a few technologies I’ve been working with recently:</p>
//           </div>

//           <ul className="skills-list">
//             {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
//           </ul>
//         </StyledText>

//         <StyledBox>
//           <div className="textbox">
//             <textarea
//               placeholder="Paste the job description here..."
//               value={jobDescription}
//               onChange={(e) => setJobDescription(e.target.value)}
//             />
//             <button onClick={handleAnalyzeJob}>Analyze</button>
//             {response && <div className="response">{response}</div>}
//           </div>
//         </StyledBox>
//       </div>
//     </StyledAboutSection>
//   );
// };

// export default About;
// import React, { useEffect, useRef, useState } from 'react';
// import styled from 'styled-components';
// import { srConfig } from '@config';
// import sr from '@utils/sr';
// import { usePrefersReducedMotion } from '@hooks';

// const StyledAboutSection = styled.section`
//   max-width: 900px;

//   .inner {
//     display: grid;
//     grid-template-columns: 3fr 2fr;
//     grid-gap: 30px;

//     @media (max-width: 768px) {
//       display: block;
//     }
//   }
// `;
// const StyledText = styled.div`
//   ul.skills-list {
//     display: grid;
//     grid-template-columns: repeat(2, minmax(140px, 200px));
//     grid-gap: 0 10px;
//     padding: 0;
//     margin: 20px 0 0 0;
//     overflow: hidden;
//     list-style: none;

//     li {
//       position: relative;
//       margin-bottom: 10px;
//       padding-left: 20px;
//       font-family: var(--font-mono);
//       font-size: var(--fz-xs);

//       &:before {
//         content: '▹';
//         position: absolute;
//         left: 0;
//         color: var(--green);
//         font-size: var(--fz-sm);
//         line-height: 12px;
//       }
//     }
//   }
// `;
// const StyledBox = styled.div`
//   position: relative;
//   max-width: 400px;

//   @media (max-width: 768px) {
//     margin: 50px auto 0;
//     width: 70%;
//   }

//   .textbox {
//     ${({ theme }) => theme.mixins.boxShadow};
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     width: 140%;
//     border-radius: var(--border-radius);
//     background-color: var(--light-navy);
//     padding: 15px;

//     textarea {
//       width: 100%;
//       height: 100px;
//       margin-bottom: 10px;
//       border-radius: var(--border-radius);
//       border: 1px solid var(--green);
//       padding: 10px;
//       font-size: var(--fz-sm);
//       font-family: var(--font-mono);
//     }

//     button {
//       background-color: var(--navy);
//       color: var(--white);
//       border: none;
//       padding: 10px;
//       border-radius: var(--border-radius);
//       cursor: pointer;
//       font-family: var(--font-mono);
//       font-size: var(--fz-sm);

//       &:hover {
//         background-color: var(--green);
//         color:var(--navy);
//       }
//     }

//     .response {
//       margin-top: 10px;
//       font-size: var(--fz-sm);
//       color: var(--green);
//     }

//     .loading {
//       font-size: var(--fz-sm);
//       color: var(--green);
//       margin-top: 10px;
//     }
//   }

//   .response-box {
//     ${({ theme }) => theme.mixins.boxShadow};
//     padding: 20px;
//     border-radius: var(--border-radius);
//     width: 155%;
//     background-color: var(--light-navy);
//     border: 2px solid var(--green);
//     opacity: 0;
//     transform: scale(0.9);
//     transition: opacity 0.3s ease, transform 0.3s ease;

//     &.visible {
//       opacity: 1;
//       transform: scale(1);
//     }

//     h3 {
//       margin-bottom: 10px;
//       color: var(--lightest-slate);
//     }

//     p {
//       color: var(--white);
//     }
//   }
// `;

// const About = () => {
//   const revealContainer = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();
//   const [jobDescription, setJobDescription] = useState('');
//   const [response, setResponse] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [submitted, setSubmitted] = useState(false);

//   useEffect(() => {
//     if (prefersReducedMotion) {
//       return;
//     }

//     if (revealContainer.current) {
//       sr.reveal(revealContainer.current, srConfig());
//     }
//   }, [prefersReducedMotion]);

//   const handleAnalyzeJob = async () => {
//     setLoading(true);
//     try {
//       const url = 'https://api.groq.com/openai/v1/chat/completions';

//       const headers = {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer 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`, // Replace YOUR_API_KEY with your actual API key
//       };

//       const prompt = `Using the following details about Roshan Ravindran, craft a single-paragraph response (maximum 200 words) that highlights how Roshan's skills and experience align with the given job description. Include a brief description of Roshan's relevant job roles, tools or technologies used, certifications, and research. The response should be professional and polished. If the job description is invalid, respond with: "Please provide a valid job description for analysis."

// Roshan Ravindran is a highly skilled AI developer and data scientist with a Master’s in Computer Science (3.91 GPA) from Virginia Tech, specializing in Artificial Intelligence. Roshan’s professional experience includes:

// - **AI Development Intern at Fetchrocket (Jul 2024 – Nov 2024)**: Developed AI features using the MERN stack, OpenAI APIs, Pinecone, Langchain, and Google Cloud. Fine-tuned response models and optimized educational AI applications.
// - **ML Research Intern at Virginia Tech (May 2023 – Aug 2024)**: Preprocessed datasets with over 42,000 columns using Pandas, NumPy, and Scikit-learn. Built interactive websites to present predictive models and insights.
// - **ML Research Intern at Georgia Tech (Sept 2023 – Jan 2024)**: Created a real-time traffic light detection system using Python, OpenCV, YOLOv8, and EvaDB for object detection.
// - **ML Research Intern at Virginia Tech - Myers-Lawson School of Construction (Sept 2023 – Jan 2024)**: Optimized deep learning models for hazard detection in construction environments, leveraging TensorFlow and reducing false positives by 20%.
// - **Undergraduate Research Assistant at Virginia Tech (Sept 2021 – Mar 2022)**: Extracted and tested code snippets using Selenium and BeautifulSoup4, achieving a 97% accuracy rate in web scraping and data analysis.
// - **Web Development and Marketing Intern at 360 Marketing (Mar 2021 – May 2021)**: Built dynamic QR code-based websites for hotels using WordPress, PHP, and custom JavaScript.

// Roshan’s **research work** includes contributing to *XLCoST: A Benchmark Dataset for Cross-lingual Code Intelligence*, published in June 2022. This project showcased Roshan’s expertise in developing datasets for machine learning applications.

// Notable projects include:
// - **HokieAI Chatbot**: Built using the MERN stack, integrating OpenAI technologies and Microsoft Calendar.
// - **DebugMaster AI**: A debugging platform leveraging AI and Kubernetes for automated issue detection.
// - **NBA PRA Predictor**: Sports analytics tool achieving a 71.3% accuracy rate using Scikit-learn, Pandas, and predictive modeling.

// Certifications:
// - **AWS Certified Cloud Practitioner (Aug 2023)**: Demonstrates a strong understanding of cloud architecture and services.

// Skills: Python, React.js, TensorFlow, Kubernetes, LangChain, SQL, AWS, Google Cloud, Tableau, MATLAB, Docker, OpenCV, and Scikit-learn.

// Write a paragraph describing how Roshan fits the role based on this job description:

// ${jobDescription}`;

//       const body = {
//         model: 'llama-3.3-70b-versatile', // Replace with the desired model ID
//         messages: [
//           {
//             role: 'system',
//             content: 'You are a helpful assistant.',
//           },
//           {
//             role: 'user',
//             content: prompt,
//           },
//         ],
//         max_completion_tokens: 256,
//         temperature: 0.7,
//         top_p: 1,
//       };

//       const response = await fetch(url, {
//         method: 'POST',
//         headers,
//         body: JSON.stringify(body),
//       });

//       if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//       }

//       const data = await response.json();
//       setResponse(data.choices[0].message.content);
//       setSubmitted(true);
//     } catch (error) {
//       setResponse('Error analyzing the job description. Please try again.');
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Eleventy', 'Node.js', 'WordPress'];

//   return (
//     <StyledAboutSection id="about" ref={revealContainer}>
//       <h2 className="numbered-heading">About Me</h2>

//       <div className="inner">
//         <StyledText>
//             <p>
//             Hello! I’m Roshan Ravindran, a recent graduate from Virginia Tech with a Bachelor’s and Master’s degree in Computer Science, specializing in Artificial Intelligence. I graduated with my master’s in December 2024, and my main areas of expertise are software development, generative AI, and machine learning.

// I enjoy building innovative and impactful applications, with a strong foundation in full-stack development using the MERN stack. My experience in generative AI includes fine-tuning and deploying models for practical use cases, such as educational tools, chatbots, and debugging platforms. In machine learning, I’ve worked on predictive modeling, deep learning systems, and computer vision projects, applying these technologies to solve complex problems efficiently.

// I’m excited to contribute to projects that bring together software development, generative AI, and machine learning to create cutting-edge solutions. If you’d like to collaborate or learn more about my work, feel free to reach out or explore my portfolio!


//             </p>
//         </StyledText>

//         <StyledBox>
//           {!submitted ? (
//             <div className="textbox">
//               <textarea
//                 placeholder="Paste the job description here..."
//                 value={jobDescription}
//                 onChange={(e) => setJobDescription(e.target.value)}
//               />
//               <button onClick={handleAnalyzeJob} disabled={loading}>
//                 {loading ? 'Retrieving...' : 'Align with Roshan'}
//               </button>
//               {loading && <div className="loading">Retrieving information from RoshAI...</div>}
//             </div>
//           ) : (
//             <div className={`response-box ${submitted ? 'visible' : ''}`}>
//               <h3>How Roshan Fits Into Your Company</h3>
//               <p dangerouslySetInnerHTML={{ __html: response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }} />
//             </div>
//           )}
//         </StyledBox>
//       </div>
//     </StyledAboutSection>
//   );
// };

// export default About;






import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 30px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledBox = styled.div`
  position: relative;
  max-width: 400px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .textbox {
    ${({ theme }) => theme.mixins.boxShadow};
    display: flex;
    flex-direction: column;
    position: relative;
    width: 140%;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    padding: 15px;

    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      border-radius: var(--border-radius);
      border: 1px solid var(--green);
      padding: 10px;
      font-size: var(--fz-sm);
      font-family: var(--font-mono);
    }

    button {
      background-color: var(--navy);
      color: var(--white);
      border: none;
      padding: 10px;
      border-radius: var(--border-radius);
      cursor: pointer;
      font-family: var(--font-mono);
      font-size: var(--fz-sm);

      &:hover {
        background-color: var(--green);
        color: var(--navy);
      }
    }

    .description {
      margin-top: 10px;
      padding: 10px;
      font-size: var(--fz-sm);
      font-family: var(--font-mono);
      background-color: var(--navy);
      border: 2px dashed var(--green);
      border-radius: var(--border-radius);
      color: var(--green);
      text-align: center;
      font-weight: bold;

      &::before {
        content: '💡 ';
        font-size: var(--fz-sm);
      }
    }

    .response {
      margin-top: 10px;
      font-size: var(--fz-sm);
      color: var(--green);
    }

    .loading {
      font-size: var(--fz-sm);
      color: var(--green);
      margin-top: 10px;
    }
  }

  .response-box {
    ${({ theme }) => theme.mixins.boxShadow};
    padding: 20px;
    border-radius: var(--border-radius);
    width: 155%;
    background-color: var(--light-navy);
    border: 2px solid var(--green);
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;

    &.visible {
      opacity: 1;
      transform: scale(1);
    }

    h3 {
      margin-bottom: 10px;
      color: var(--lightest-slate);
    }

    p {
      color: var(--white);
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [jobDescription, setJobDescription] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    if (revealContainer.current) {
      sr.reveal(revealContainer.current, srConfig());
    }
  }, [prefersReducedMotion]);

  const handleAnalyzeJob = async () => {
    console.log("API Key:", process.env.REACT_APP_GROQ_API_KEY);

    setLoading(true);
    try {
      const url = 'https://api.groq.com/openai/v1/chat/completions';

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer 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`, // Replace with your actual API key
      };

      const prompt = `Using the following details about Roshan Ravindran, craft a single-paragraph response (maximum 200 words) that highlights how Roshan's skills, experience, and expertise align with the given job description.
---
### Candidate Profile:

Roshan Ravindran is a highly skilled student with a **Master’s in Computer Science (3.91 GPA)** from Virginia Tech, specializing in **Artificial Intelligence**. He completed his undergrad at Virginia Tech with a degree in Computer Science right before his master's. With a strong technical background in **software development, machine learning, GenAI, and AI research**, Roshan has successfully applied advanced AI techniques to solve complex real-world problems.

### **Professional Experience:**
- **AI Development Intern at Fetchrocket (Jul 2024 – Nov 2024)**  
  - Developed AI-driven features using **MERN stack, OpenAI APIs, Gemini, Pinecone, and Langchain**.  
  - Fine-tuned **response models** and leveraged **Google Cloud & MySQL** for optimized AI applications.  

- **ML Research Intern at Virginia Tech – VCU (May 2023 – Aug 2024)**  
  - Processed **large-scale datasets (42,000+ columns)** using **Pandas, NumPy, Matplotlib, and Scikit-learn**.  
  - Applied **MATLAB** for mathematical modeling and identified **data patterns & outliers**.  
  - Extracted datasets via **web scraping** (Census website) achieving a **95% data completeness rate**.  

- **ML Researcher at Georgia Tech (Sept 2023 – Jan 2024)**  
  - Developed **real-time traffic light detection** using **Python, OpenCV, and YOLOv8**.  
  - Leveraged **EvaDB for object detection**, achieving **15% accuracy improvement** in signal recognition.  

- **ML Research Intern at Virginia Tech - Myers-Lawson School of Construction (Sept 2023 – Jan 2024)**  
  - Built **deep learning models** for **hazard detection in construction environments**.  
  - Improved detection accuracy by **15%** and reduced **false positives by 20%**.  

- **Undergraduate Research Assistant at Virginia Tech (Sept 2021 – Mar 2022)**  
  - Developed advanced **web scraping tools** using **Selenium & BeautifulSoup4**.  
  - Achieved **97% accuracy** in extracting **parallel code snippets**, optimizing large-scale data collection.  

- **Web Development & Marketing Intern at 360 Marketing (Mar 2021 – May 2021)**  
  - Developed **dynamic QR code-based hotel websites** using **WordPress, PHP, HTML, CSS, and JavaScript**.  
  - Built an **interactive map feature** and proposed **social media campaign strategies**.  

### **Key Projects:**
- **HokieAI Chatbot**  
  - Built a **MERN-stack AI-powered student advisor** with **GPT-4, OpenAI, Microsoft Calendar integration**.  
  - Provides guidance on **academics, social life, wellness, and tutoring** with real-time data updates.  

- **DebugMaster AI**  
  - Created a **debugging platform** that automatically **analyzes GitHub issues**, generates pull requests, and suggests fixes.  
  - Utilized **Kubernetes, LangChain, MySQL, Google Cloud, Redis, GraphQL, and TensorFlow** for advanced debugging.  

- **NBA PRA Predictor**  
  - Built a **sports analytics tool** predicting **NBA player performance (points, assists, rebounds)**.  
  - Achieved **71.3% accuracy** using **Scikit-learn, Pandas, NumPy, Matplotlib, and API integration**.  

### **Leadership Experience:**
- **Founder & President of Software Development Club at Virginia Tech**  
  - Led a student-driven development team to build AI-powered applications.  
  - Developed an **interactive website** featuring **predictive models & real-time insights**.  

### **Technical Skills:**
- **Languages:** Python, JavaScript, Java, C, MATLAB, SQL, R, PHP  
- **AI/ML Frameworks:** TensorFlow, OpenCV, Scikit-learn, Pandas, NumPy, Keras, Matplotlib  
- **Databases:** MySQL, MongoDB, PostgreSQL  
- **Cloud & DevOps:** AWS, Google Cloud, Kubernetes, Docker, Hadoop  
- **Web Development:** React.js, Node.js, Express.js, HTML, CSS, JavaScript  
- **Other Tools:** Redis, GraphQL, LangChain, Pinecone, Selenium, BeautifulSoup4, Tableau  
- **Strong understanding OOP, Design Patterns, and Multithreading**

### **Certifications:**
- **AWS Certified Cloud Practitioner (Aug 2023)**   

---

### **Task:**
Write a **concise yet impactful paragraph** describing how Roshan’s expertise aligns with the job description. The response should highlight:
1. **Relevant experience & achievements** in AI, ML, software engineering, or data science. (LIST WHERE HE USED THAT SKILL WHENEVER NEEDED)
2. **Technical skills** directly applicable to the role.
3. **Projects or research contributions** that demonstrate problem-solving capabilities.
4. **Certifications or leadership roles** that reinforce credibility.
5. **Industry-relevant tools & technologies**.

---

### **Job Description Input:**
${jobDescription}`;

      const body = {
        model: 'llama-3.3-70b-versatile',
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: prompt },
        ],
        max_completion_tokens: 256,
        temperature: 0.7,
        top_p: 1,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setResponse(data.choices[0].message.content);
      setSubmitted(true);
    } catch (error) {
      setResponse('Error analyzing the job description. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <p>
          Hello! I’m Roshan Ravindran, a recent graduate from Virginia Tech with a Bachelor’s and Master’s degree in Computer Science, specializing in Artificial Intelligence. I graduated with my master’s in December 2024, and my main areas of expertise are software development, generative AI, and machine learning.

I enjoy building innovative and impactful applications, with a strong foundation in full-stack development using the MERN stack. My experience in generative AI includes fine-tuning and deploying models for practical use cases, such as educational tools, chatbots, and debugging platforms. In machine learning, I’ve worked on predictive modeling, deep learning systems, and computer vision projects, applying these technologies to solve complex problems efficiently.

I’m excited to contribute to projects that bring together software development, generative AI, and machine learning to create cutting-edge solutions. If you’d like to collaborate or learn more about my work, feel free to reach out or explore my portfolio!

          </p>
        </StyledText>

        <StyledBox>
          {!submitted ? (
            <div className="textbox">
              <textarea
                placeholder="Paste the job description here..."
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />
              <button onClick={handleAnalyzeJob} disabled={loading}>
                {loading ? 'Analyzing...' : 'Check!'}
              </button>
              {loading && <div className="loading">Retrieving information from RoshAI...</div>}
              {!submitted && (
                <div className="description">
                  Recruiters: Paste your job description above to see how Roshan's skills and
                  experiences align with your role!
                </div>
              )}
            </div>
          ) : (
            <div className={`response-box ${submitted ? 'visible' : ''}`}>
              <h3>How Roshan Fits Into Your Company</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'),
                }}
              />
            </div>
          )}
        </StyledBox>
      </div>
    </StyledAboutSection>
  );
};

export default About;
